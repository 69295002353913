@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

.App-header {
  background-color: rgb(217, 217, 217);
  background: url('./images/background.png');
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(12px + 2vmin);
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  color: #3c3b3b;
}

.Links > a > img {
  height: calc(50px + 5vmin);
  padding: 1vmin;
  display: inline;
}